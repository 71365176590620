import React from 'react';
import LogoWhite from '../assets/logowhite.png'
import '../App.css';
import BGVideo from '../assets/bgvideo.mp4'
import {redirectToCalendly, redirectToStore} from './Helpers';

class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    return (
   this.props.mobile ?  
    <div class="video-container" style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
     <div class="video-container">
    <video autoPlay muted loop>
        <source src={BGVideo} type="video/mp4" />
    </video>
    <div class="caption">
      <div style={{display:'flex',justifyContent:'space-evenly',flexDirection:'column',alignItems:'stretch',height:'773px'}}>
        <div style={{flex:'1',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
           <div style={{flex:1,justifyContent:'left',textAlign:'left',paddingLeft:'10px'}}>
              <a href="https://www.gridspot.co"> <img src={LogoWhite} width='150px'/> </a>
           </div> 
           <div style={{flex:'1',paddingRight:'20px',textAlign:'right'}}>
             <div class="download-button" onClick={e=>redirectToStore()}> Download App </div>
           </div>      
        </div> 
        <div style={{flex:'1',width:'100%'}}> 
           <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
              <div style={{flex:'1'}}>
              
              </div>
              <div style={{flex:'6',textAlign: 'center',fontFamily: 'Helvetica',fontSize: '56px',fontWeight: '400',lineHeight:'130%',margin:'auto'}}>
                   Efforlessly Keep Your Tenants' Electric Vehicles <b> POWERED </b>
	      <br/><br/> <div class="download-button" onClick={e=>redirectToCalendly()} style={{backgroundColor:'#23AAE1'}}> Get Started </div>
              </div>
              <div style={{flex:'1',textAlign:'right',margin:'15px'}}>
                 <span class="scroll-down-text" style={{writingMode: 'vertical-rl',maxHeight:'350px',maxWidth:'20px'}}>
                   SCROLL DOWN
                 </span>
              </div>
           </div>
        </div>
        <div style={{flex:'1',marginTop:'70px',paddingBottom:'10px',textAlign:'center'}}> Enjoy the Amenity EV Charging Without Hassle Today </div>
    </div> 
    </div>
</div>        
    </div> 



   :



    <div class="video-container" style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
     <div class="video-container">
    <video autoPlay muted loop>
        <source src={BGVideo} type="video/mp4" />
    </video>
    <div class="caption">
      <div style={{display:'flex',justifyContent:'space-evenly',flexDirection:'column',alignItems:'stretch',height:'773px'}}>
        <div style={{flex:'1',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
           <div style={{flex:1,justifyContent:'left',textAlign:'left',paddingLeft:'10px'}}>
             <a href="https://www.gridspot.co"> <img src={LogoWhite} width='150px'/> </a>
           </div> 
           <div style={{flex:3,marginTop:'4px'}}>
             <div class="header-menu">
               <div style={{color:'#23AAE1'}}>
                 <b> Home </b>
               </div>
               <div style={{cursor:'pointer'}} onClick={e=>this.props.pageChanged('how')}>
                 How It Works
               </div>  
               <div style={{cursor:'pointer'}} onClick={e=>this.props.pageChanged('contact')}>
                 Contact
               </div> 
             </div>
           </div>
           <div style={{flex:'1',paddingRight:'20px',textAlign:'right'}}>
             <div class="download-button" onClick={e=>redirectToStore()}> Download App </div>
           </div>      
        </div> 
        <div style={{flex:'1',width:'100%'}}> 
           <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
              <div style={{flex:'1'}}>
              
              </div>
              <div style={{flex:'6',textAlign: 'center',fontFamily: 'Helvetica',fontSize: '56px',fontWeight: '400',lineHeight:'130%',margin:'auto'}}>
                   Efforlessly Keep Your Tenants' Electric Vehicles <b> POWERED </b>
	      <br/><br/><br/><br/> <div class="download-button" onClick={e=>redirectToCalendly()} style={{backgroundColor:'#23AAE1'}}> Get Started </div>
              </div>
              <div style={{flex:'1',textAlign:'right',margin:'15px'}}>
                 <span class="scroll-down-text" style={{writingMode: 'vertical-rl',maxHeight:'350px',maxWidth:'20px'}}>
                   SCROLL DOWN
                 </span>
              </div>
           </div>
        </div>
        <div style={{flex:'1',marginTop:'50px',paddingBottom:'10px',textAlign:'center'}}> Enjoy the Amenity of EV Charging Without Hassle Today </div>
    </div> 
    </div>
</div>        
    </div> 
    )
  }
}

export default Header;
